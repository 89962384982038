body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0d1117;
  color: #c9d1d9;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Improve scrollbar appearance */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #161b22;
}

::-webkit-scrollbar-thumb {
  background: #30363d;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #3f444c;
}

/* Remove default table striping if any */
tr:nth-child(even) {
  background-color: transparent;
}

/* Add subtle hover effect to table rows */
tr:hover td {
  background-color: #1c2128;
}

/* Smooth transitions for all interactive elements */
button, a {
  transition: all 0.2s ease-in-out;
}

/* Selection color */
::selection {
  background-color: #388bfd44;
  color: #c9d1d9;
}

/* Focus outline */
:focus {
  outline: 2px solid #388bfd;
  outline-offset: 2px;
}

/* Focus visible only when using keyboard */
:focus:not(:focus-visible) {
  outline: none;
}

/* Disable text selection on interactive elements */
button,
[role="button"] {
  user-select: none;
}
